* {
  margin: 0;
  padding: 0;
}

div, p, a, h3, h4, h5, h6 {
  font-family: 'Inter', sans-serif;
}

h1, h2 {
  font-family: 'Playfair Display', serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


a {
  color: #000;
}

.hidden {
  display: none;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  max-width: 50rem;
  margin: 4rem 1rem;
  gap: 4rem;
}

hr {
  border: 0.5px solid black;
  width: 100%;
}

/* Header */
.app-header {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

/* Nav */
.nav ul {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  font-size: 1.75em;
  list-style: none;
}


.nav a {
  color: black;
  opacity: 0.5;
  text-decoration: none;
}

.nav a:hover {
  color: black;
  opacity: 1;
  text-decoration: underline;
}

.nav .active {
  color: black;
  opacity: 1;
}

/* Contact */
.contact {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  gap: 1rem;
}

.contact * {
  flex: 1;
}

.contact ul {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  list-style: none;
  gap: .25rem;
}

/* Bio */
.bio {
  display: flex;
  width: 55%;
  font-size: 2.25em;
  color: #497DC8;
  font-family: Playfair Display;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* About */
.about {
  display: flex;
  flex-direction: column;
  padding: 0rem 3rem;
  align-items: flex-start;
  gap: .85em;
  align-self: stretch;
  font-size: 1.3em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.65em; /* 166.667% */
}

/* Resume */
.resume {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4rem;
  align-self: stretch;
}

.resume h4 {
  opacity: 0.5;
}

.experience {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
}

.position {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
}

.position-header {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.complex-position {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}

.company {
  font-weight: 600;
}

.interpunct {
  display: flex;
  flex: 1;
  border: .5px dashed #000;
}

.spacer {
  width: 1.5rem;
}

.clients-and-skills {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  align-self: stretch;
}

.clients-and-skills p {
  font-family: Playfair Display;
  font-size: 2.25rem;
}

/* Projects */
.projects {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 0 3rem;
}

.projects section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.projects h4 {
  margin-top: 1em;
}

.roles-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style: none;

}

/* Footer */
footer {
  display: flex;
  flex-direction: row;
  align-self: stretch;
}

footer :first-child {
  display: flex;
  flex: 1;  
  align-self: flex-start;
}

@media screen and (max-width: 600px) {
  .position-header {
    flex-direction: column;
    gap: 0;
  }

  .about, .projects {
    padding: 0;
  }
}

/* 

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
